import Form from "components/Forms/Form"
import Input from "components/Forms/Input"
import If from "components/If"
import Button from "components/Button"
import AuthFeedback from "components/Auth/Feedback"

import useChangePassword from "./hooks"
import { values, validation } from "./form"

import "./FormChangePassword.styl"

const FormChangePassword = ({ location }) => {
  const { feedbacks, onSubmit, onConcluded } = useChangePassword({
    location,
  })

  const isForm = !feedbacks.error && !feedbacks.success

  return (
    <>
      <If condition={isForm}>
        <div className="change-password">
          <h1 className="change-password__title logged-out__title">
            Recadastrar senha
          </h1>
          <p
            className="change-password__description logged-out__description"
            data-test="description"
          >
            Sua nova senha deve ter pelo menos 8 caracteres contendo no mínimo,
            1 maiúsculo, 1 minúsculo, 1 especial.
          </p>
          <Form
            className="change-password__form"
            defaultValues={values}
            onSubmit={onSubmit}
            schema={validation}
          >
            <Input
              className="change-password__input"
              name="password"
              type="password"
              placeholder="Nova senha"
              data-test="password"
            />
            <Input
              className="change-password__input"
              name="confirm_password"
              type="password"
              placeholder="Confirmar a nova senha"
              data-test="confirm-password"
            />
            {({ state: { isDisabled } }) => (
              <Button
                className="change-password__submit"
                type="submit"
                size="big"
                disabled={isDisabled}
                data-test="submit"
              >
                Enviar
              </Button>
            )}
          </Form>
        </div>
      </If>
      <If condition={feedbacks.success}>
        <AuthFeedback
          icon="sent"
          title=" Senha trocada"
          description="Sua senha nova foi cadastrada e você já pode usar para acessar o sistema."
          button="Voltar para a tela de acesso"
          onClick={onConcluded}
          data-test="feedback"
        />
      </If>
      <If condition={feedbacks.error}>
        <AuthFeedback
          icon="lock"
          title="Erro na redefinição"
          description={feedbacks?.error?.message}
          button="Voltar para a tela de acesso"
          onClick={onConcluded}
          data-test="feedback"
        />
      </If>
    </>
  )
}

export default FormChangePassword
