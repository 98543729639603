import FormChangePassword from "./components/FormChangePassword"

const ChangePassword = ({ location }) => {
  return (
    <div className="change-password">
      <FormChangePassword location={location} />
    </div>
  )
}

export default ChangePassword
