import { navigate } from "gatsby"
import { useState } from "react"
import { useParameters } from "hooks/useParameters"
import services from "services"

const initialStates = {
  error: null,
  success: null,
}

const useChangePassword = () => {
  const [feedbacks, setFeedbacks] = useState(initialStates)

  const {
    token: [token],
    email: [email],
  } = useParameters("token", "email")

  const onSubmit = ({ password, confirm_password }) => {
    services.auth
      .resetPassword({
        token,
        email,
        password,
        confirm_password,
      })
      .then(handleChangedPassword)
  }

  const onConcluded = () => navigate(ROUTES.login.path)

  const handleChangedPassword = ({ error, message }) => {
    setFeedbacks({
      error: error && { message },
      success: !error,
    })
  }

  return {
    feedbacks,
    onSubmit,
    onConcluded,
  }
}

export default useChangePassword
