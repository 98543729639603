import * as Yup from "yup"
import validators from "utils/validators"

export const values = {
  password: "",
  context_password: "",
}

export const validation = Yup.object().shape({
  password: Yup.string()
    .required("Campo obrigatório")
    .test("validPassword", "Senha não possui todos os requisitos", (value) =>
      validators.password(value)
    ),
  confirm_password: Yup.string()
    .required("Campo obrigatório")
    .test(
      "validSamePassword",
      "Senhas não são iguais",
      (value, context) => context.parent.password === value
    ),
})
